import * as lax from "lax.ts";
import Lightbox from 'bs5-lightbox';

const SCROLL_TOP = 30
const ONLOAD_ANIMATION_MS = 10
const ANIMATION_DURATION_MS = 2000

var isWindowLoaded = false
const optionsLb = {
    keyboard: true,
    touch: true,
    wrap: false,
    size: 'fullscreen'
};


function initLax() {
    lax.init(); // init

    lax.addDriver('scrollY', function () {
        return window.scrollY
    }, { frameStep: 1 });
    lax.addDriver('isLoaded', function () {
        return isWindowLoaded ? 1 : 0
    }, { frameStep: 1 })
}

window.onload = () => {
    registerActions()
    initLax()
    onLoadLaxElements()
    afterLoadLaxElements()
    initGallery()
}

function registerActions() {
    // On scroll
    const el1 = document.getElementById("header")
    if (el1)
        window.addEventListener("scroll", () => {
            if (document.body.scrollTop >= SCROLL_TOP || document.documentElement.scrollTop >= SCROLL_TOP)
                el1.classList.add("scrolled")
            else
                el1.classList.remove("scrolled")
        })
}

function onLoadLaxElements() {
    lax.addElements('.lax-header', {
        isLoaded: {
            translateY: [[0, 1], ["-screenHeight", 0]],
            translateZ: [[0,1],[0,0]],
            translateX: [[0,1],[0,0]],
            opacity: [[0, 1], [0, 1]]
        },
    }, { style: { transition: 'transform 1s ease, opacity 2s ease', "z-index": 0 } });


    lax.addElements('.lax-content', {
        scrollY: {
            opacity: [[0, 300], [0, 1]],
            translateY: [[0,1],[0,0]],
            translateZ: [[0,1],[0,0]],
            translateX: [[0,1],[0,0]],
        },
    }, { style: { "z-index": 1 } });

    lax.addElements('.lax-arrow', {
        isLoaded: {
            opacity: [[0, 1], [0, 1]],
            translateY: [[0,1],[0,0]],
            translateZ: [[0,1],[0,0]],
            translateX: [[0,1],[0,0]],
        }
    }, { style: { transition: 'transform 1s ease, opacity 1s ease' , "z-index": 0 } });

    lax.addElements('.lax-header-bg', {
        isLoaded: {
            opacity: [[0, 1], [0, 1]],
            translateY: [[0, 1], ["-100", 0]],
            translateZ: [[0,1],[0,0]],
            translateX: [[0,1],[0,0]],
        }
    }, { style: { transition: 'transform 1s ease, opacity 500ms ease', "z-index": -2 } });

    lax.addElements('.lax-header-fg', {
        isLoaded: {
            opacity: [[0, 1], [0, 1]],
            translateY: [[0, 1], ["300", 0]],
            translateZ: [[0,1],[0,0]],
            translateX: [[0,1],[0,0]],
        }
    }, { style: { transition: 'transform 1s ease, opacity 500 ease', "z-index": -1 } });
}
function afterLoadLaxElements() {
    isWindowLoaded = true
    setTimeout(afterLoadLaxElementsAction, ONLOAD_ANIMATION_MS)
}
function afterLoadLaxElementsAction() {
    setTimeout(() => {
        // Header
        lax.removeElements(".lax-header")
        lax.removeElements('.lax-header-bg')
        lax.removeElements('.lax-header-fg')
        lax.removeElements('.lax-arrow')
        lax.removeDriver("isLoaded")
        lax.addElements(".lax-header", {
            scrollY: {
                translateY: [[0, 400], [0, -400]],
                translateZ: [[0,1],[0,0]],
                translateX: [[0,1],[0,0]],
            },
        }, { style: { transition: 'unset', "z-index": 1 } })
        lax.addElements('.lax-header-bg', {
            scrollY: {
                translateY: [[0, 400], [1, -100]],
                translateZ: [[0,1],[0,0]],
                translateX: [[0,1],[0,0]],
            },
        }, { style: { transition: 'unset', "z-index": -2 } });

        lax.addElements('.lax-header-fg', {
            scrollY: {
                translateY: [[0, 500], [1, -260]],
                translateZ: [[0,1],[0,0]],
                translateX: [[0,1],[0,0]],
            },
        }, { style: { transition: 'unset', "z-index": -1 } });

        lax.addElements('.lax-arrow', {
            scrollY: {
                opacity: [[0, 200], [1, 0]],
                translateY: [[0,1],[0,0]],
                translateZ: [[0,1],[0,0]],
                translateX: [[0,1],[0,0]],
            },
        }, { style: { transition: 'unset', "z-index": 0 } });
    }, ONLOAD_ANIMATION_MS + ANIMATION_DURATION_MS)
}

function initGallery() {
    document.querySelectorAll('.lb').forEach((el:any) => el.addEventListener('click', (e:any) => {
        e.preventDefault();
        const lightbox = new Lightbox(el, optionsLb);
        lightbox.modalElement.style.zIndex = "9000"
        lightbox.carouselElement.style.zIndex = "9001"
        lightbox.show();
    }));
}